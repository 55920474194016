<template>
  <div>
    <div class="banner">
      <div class="swiper-container" >
        <div class="swiper-wrapper" >
          <div class="swiper-slide"  v-for="(item,index) in banner" :key="index"><a style="cursor: pointer" @click="navTo(item)" ><img :src="$baseURL + item.imgUrl"></a></div>
<!--          <div class="swiper-slide"><a href="#"><img src="images/banner.jpg"></a></div>-->
<!--          <div class="swiper-slide"><a href="#"><img src="images/banner.jpg"></a></div>-->
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="homepage">
      <div class="container">
        <div class="a">
          <div class="hd">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div  class="swiper-slide" v-for="item in swiper" :key="item.id" @click="toDetail2(item)"><a style="cursor: pointer;">{{item.title}}</a></div>
              </div>
              <!-- Add Pagination -->
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
          <div class="bd">
            <div class="clearfix row">
              <div class="col-5">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in cards" :key="item.id">
                      <a @click="toDetail2(item)"><img :src="$baseURL + item.imgUrl" style="height: 378px"></a>
                      <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-right: 140px">{{item.title}}</p>
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
              <div class="col-5">
                <div class="box" >
                  <ul class="tabtitle flex">
                    <li :class="{on:index==tabIndex1}" @click="tabClick1(index)" v-for="(item,index) in allData[0]" :key="index">{{item.name}}</li>
                    <a href="javascript:;" class="more" @click="toMore(allData[0][tabIndex1])">更多</a>
                  </ul>
                  <div class="tabcontent" v-if="allData[0] && allData[0][tabIndex1]">
                    <!--列表模型-->
                    <div class="tabbox" v-if="allData[0][tabIndex1].showType == 0">
                      <ul class="newslist" >
                        <li v-for="item in allData[0][tabIndex1].articles.slice(0,6)" :key="item.id" >
                          <div class="flex_item"><a @click="toDetail(allData[0][tabIndex1],item.id)">
                            <span style="color: red;" v-if="item.isTop==1">【顶】</span>
                            {{item.title}}
                          </a></div>
                          <span>{{item.publishTime | format}}</span>
                        </li>
                      </ul>
                    </div>
                    <!--栏目模型1-->
                    <div class="tabbox" v-else-if="allData[0][tabIndex1].showType == 1">
                      <ul class="newslist" >
                        <li v-for="item in allData[0][tabIndex1].columns.slice(0,6)" :key="item.id" >
                          <div class="flex_item"><a  style="font-weight: bold"  @click="toMore2(allData[0][tabIndex1],item.id)">{{item.name}}</a></div>
                          <span>{{item.createTime | format}}</span>
                        </li>
                      </ul>
                    </div>
                    <!--栏目模型2-->
                    <div class="tabbox" v-else-if="allData[0][tabIndex1].showType == 2">
                      <div class="box1">
                        <div class="box-item" @click="toMore2(allData[0][tabIndex1],item.id)" v-for="item in allData[0][tabIndex1].columns.slice(0,6)" :key="item.id">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ft" style="width: 1200px"  v-if="adv2.length > 0 && adv2[0]">
            <a @click="navTo(adv2[0])"><img :src="$baseURL + adv2[0].imgUrl"></a>
          </div>
        </div>
        <div class="b">
          <div class="clearfix row">
            <div class="col-5" v-if="allData.length>0 && allData[1] && allData[1][0] && allData[1][0]">
              <div class="title flex">
                <div class="flex_item"><b>{{allData[1][0].name}}</b></div>
                <a href="javascript:;" @click="toMore(allData[1][0])" class="more">更多</a>
              </div>

              <!--列表模型-->
              <div class="box" v-if="allData[1][0].showType == 0">
                <ul class="newslist" >
                  <li v-for="item in allData[1][0].articles.slice(0,8)" :key="item.id">
                    <div class="flex_item"><a href="javascript:;" @click="toDetail(allData[1][0],item.id)" >
                      <span style="color: red;" v-if="item.isTop==1">【顶】</span>
                      {{item.title}}</a></div>
                    <span>{{item.publishTime | format}}</span>
                  </li>
                </ul>
              </div>

              <!--栏目模型一-->
              <div class="box" v-else-if="allData[1][0].showType == 1">
                <ul class="newslist" >
                  <li v-for="item in allData[1][0].columns.slice(0,8)" :key="item.id">
                    <div class="flex_item"><a href="javascript:;" style="font-weight: bold;" @click="toMore2(allData[1][0],item.id)">{{item.name}}</a></div>
                    <span>{{item.createTime | format}}</span>
                  </li>
                </ul>
              </div>

              <!--栏目模型二-->
              <div class="box" v-else-if="allData[1][0].showType == 2">
                <div class="box1">
                  <div class="box-item" @click="toMore2(allData[1][0],item.id)" v-for="item in allData[1][0].columns.slice(0,6)" :key="item.id">
                    {{item.name}}
                  </div>
                </div>
              </div>
              <div class="box" v-if="adv4[0]">
                <a @click="navTo(adv4[0])" style="cursor: pointer"><img :src="$baseURL + adv4[0].imgUrl"></a>
              </div>

            </div>
            <div class="col-5" v-if="allData.length > 0 && allData[2] && allData[2][0]">
              <div class="title flex">
                <div class="flex_item"><b>{{allData[2][0].name}}</b></div>
                <a href="javascript:;" @click="toMore(allData[2][0])" class="more">更多</a>
              </div>
              <!--列表模型-->
              <div class="box" v-if="allData[2][0].showType == 0">
                <template v-if="adv3[0]">
                  <a @click="navTo(adv3[0])" style="cursor: pointer"><img :src="$baseURL + adv3[0].imgUrl"></a>
                </template>
                <ul class="newslist" >
                  <li v-for="item in allData[2][0].articles.slice(0,8)" :key="item.id">
                    <div class="flex_item"><a href="javascript:;" @click="toDetail(allData[2][0],item.id)">
                      <span style="color: red;" v-if="item.isTop==1">【顶】</span>
                      {{item.title}}</a></div>
                    <span>{{item.publishTime | format}}</span>
                  </li>
                </ul>
              </div>
              <!--栏目模型1-->
              <div class="box" v-else-if="allData[2][0].showType == 1">
                <a @click="navTo(adv3[0])" style="cursor: pointer"><img :src="$baseURL + adv3[0].imgUrl"></a>
                <ul class="newslist" >
                  <li v-for="item in allData[2][0].columns.slice(0,8)" :key="item.id">
                    <div class="flex_item"><a href="javascript:;" style="font-weight: bold;" @click="toMore2(allData[2][0],item.id)">{{item.name}}</a></div>
                    <span>{{item.createTime | format}}</span>
                  </li>
                </ul>
              </div>
              <!--栏目模型二-->
              <div class="box" v-else-if="allData[2][0].showType == 2">
                <a @click="navTo(adv3[0])" style="cursor: pointer"><img :src="$baseURL + adv3[0].imgUrl"></a>
                <div class="box1">
                  <div class="box-item" @click="toMore2(allData[2][0],item.id)" v-for="item in allData[2][0].columns.slice(0,6)" :key="item.id">
                    {{item.name}}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="c">
          <div class="box">
            <div class="flex" style="width: 100%">
              <ul class="tabtitle">
                <li :class="{on:index==tabIndex2}" @click="tabClick2(index)" v-for="(item,index) in allData[3]" :key="item.id">
                  <i :class="`i${index+1}`"></i>
                  <p style="font-weight:bold;">{{item.name}}</p>
                </li>
              </ul>
              <div class="tabcontent flex_item">
                <div class="tabbox">
                  <div class="flex" v-if="allData.length>0 && allData[3] && allData[3][tabIndex2] && allData[3][tabIndex2]">
                      <div style="width: 240px;height: 253px;margin: 0 50px">
                        <template v-if="adv5[tabIndex2]">
                          <a @click="navTo(adv5[tabIndex2])"><img style="margin: 0 !important;" :src="$baseURL + adv5[tabIndex2].imgUrl"></a>
                        </template>
                      </div>
                    <div class="flex_item">
                      <ul class="newslist" >
                        <li v-for="item in allData[3][tabIndex2].articles.slice(0,5)" :key="item.id">
                          <div class="flex_item"><a href="javascript:;" @click="toDetail(allData[3][tabIndex2],item.id)">
                            {{item.title}}
                            <!--<span>2018年修正本</span>-->
                            </a></div>
                          <span>{{item.publishTime | format}}</span>
                        </li>
                      </ul>
                      <div class="ft">
                        <a href="javascript:;" @click="toMore(allData[3][tabIndex2])">查看更多......</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        固定的合作伙伴-->
        <div class="d" v-if="partner.length>0">
          <div class="title flex">
            <div class="flex_item"><b>{{stations && stations.id == 1004 ? '服务平台' : '合作伙伴'}}</b></div>
<!--            <a href="#" class="more">更多</a>-->
          </div>
          <div class="box">
            <ul class="clearfix">
              <li v-for="item in partner.slice(0,4)" :key="item.id">
                <a :href="item.pathUrl" target="_blank">
                  <div class="pic"><img :src="$baseURL + item.imgUrl"></div>
                  <p>{{item.name}}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="e">
          <div class="row clearfix">
            <div class="col-5">
              <div class="box" v-if="allData.length>0 && allData[4] && allData[4][tabIndex3]">
                <ul class="flex tabtitle">
                  <li :class="{on:index==tabIndex3}" @click="tabClick3(index)" v-for="(item,index) in allData[4]" :key="index" style="font-weight:bold;">{{item.name}}</li>
                  <a href="javascript:;" @click="toMore(allData[4][tabIndex3])" class="more">更多</a>
                </ul>
                <div class="tabcontent" v-if="allData[4][tabIndex3].showType == 0">
                  <div class="tabbox">
                    <ul class="newslist" >
                      <li v-for="item in allData[4][tabIndex3].articles.slice(0,6)" :key="item.id">
                        <div class="flex_item"><a href="javascript:;"  @click="toDetail(allData[4][tabIndex3],item.id)">
                          <span style="color: red;" v-if="item.isTop==1">【顶】</span>
                          {{item.title}}</a></div>
                        <template v-if="allData[4][tabIndex3].name =='培训计划'">
                          <button v-if="boolTime(item.endTime)" type="button" class="btn" @click.stop.prevent="toPlan(item.id)">我要申请</button>
                          <span v-else>已结束</span>
                        </template>
                        <template v-else>
                          <span >{{parseTime('',item.createTime)}}</span>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tabcontent" v-else-if="allData[4][tabIndex3].showType == 1">
                  <div class="tabbox">
                    <ul class="newslist" >
                      <li v-for="item in allData[4][tabIndex3].columns.slice(0,6)" :key="item.id">
                        <div class="flex_item"><a href="javascript:;" style="font-weight: bold" @click="toMore2(allData[4][tabIndex3],item.id)">{{item.name}}</a></div>
                        <span>{{item.createTime | format}}</span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-5 right" v-if="allData.length > 0 && allData[5] && allData[5][0] && allData[5][0]">
              <div class="title flex">
                <div class="flex_item"><b>{{allData[5][0].name}}</b></div>
                <a href="javascript:;" @click="toMore(allData[5][0])" class="more">更多</a>
              </div>

              <div class="box" v-if="allData[5][0].showType == 0">
                <template v-if="adv6[0]">
                  <a @click="navTo(adv6[0])"><img :src="$baseURL + adv6[0].imgUrl"></a>
                </template>
                <ul class="newslist" >
                  <li v-for="item in allData[5][0].articles.slice(0,3)" :key="item.id">
                    <div class="flex_item" ><a href="javascript:;"   @click="toDetail(allData[5][0],item.id)">
                      <span style="color: red;" v-if="item.isTop==1">【顶】</span>
                      {{item.title}}</a></div>
                    <span>{{item.publishTime | format}}</span>
                  </li>
                </ul>
              </div>
              <div class="box" v-else-if="allData[5][0].showType == 1">
                <template v-if="adv6[0]">
                  <a @click="navTo(adv6[0])"><img :src="$baseURL + adv6[0].imgUrl"></a>
                </template>
                <ul class="newslist" >
                  <li v-for="item in allData[5][0].columns.slice(0,3)" :key="item.id">
                    <div class="flex_item" ><a href="javascript:;" style="font-weight: bold;"   @click="toMore2(allData[5][0],item.id)">{{item.name}}</a></div>
                    <span>{{item.createTime | format}}</span>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div class="f" v-if="fLinks.length>0">
          <div class="title flex">
            <div class="flex_item"><b>友情链接</b></div>
<!--            <a href="#" class="more">更多</a>-->
          </div>
          <div class="box">
            <ul class="clearfix">
              <li v-for="item in fLinks" :key="item.id"><a :href="item.pathUrl" target="_blank"><img :src="$baseURL + item.imgUrl"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
<!--      <float-view  :json="json" @closeView="closeView"></float-view>-->
  </div>
</template>

<script>
import {loadSwiper} from "@/libs";

import {mapMutations,mapState} from 'vuex'
import {parseTime} from "@/libs/utils";

export default {
  name: "home",
  data(){
    return {
      showView:true,
      banner:[],
      adv2:[],
      adv3:[],
      adv4:[],
      adv5:[],
      adv6:[],
      swiper:[],
      cards:[],
      tab1:['通知公告','认证业务'],
      certData:[],//
      tabIndex1:0,
      tabData1:[],
      hydt:[],
      gzdt:[],
      tab2:['法律法规','公开文件','证书公告'],
      tabIndex2:0,
      tabData2:[],
      partner:[],
      tab3:['培训计划','资料下载'],
      tabIndex3:0,
      tabData3:[],
      zspj:[],
      fLinks:[],
      allData:[]
    }
  },
  activated() {
    this.setShowTips(true)
    $("#left").show();
    $("#right").show();
    $("#float").show()
    setTimeout(()=>{
      loadSwiper();
    },100)
  },
  mounted() {

    //加载执行轮播加载
    this.getBanner()
    //滚动横幅
    this.getSwiper()
    //卡片轮播
    this.getCards()
    //公告栏
    // this.getBulletin();
    // this.getTab1();
    // this.gethydt();
    // this.getgzdt();
    // this.getTab2();
    this.getPartner()
    // this.getTab3();
    // this.getzspj();
    this.getFriendLink()
    this.getIndexColumn();
  },
  filters:{
    format(x){
      return x.split(" ")[0]
    }
  },
  computed:{
    ...mapState(['stations'])
  },
  methods:{
    ...mapMutations(['setShowTips']),
    parseTime,
    toPlan(id){
      this.$router.push('/channels/374/393.html?planId='+id)
    },
    boolTime(date){
      let listDate = new Date(date);
      let now = new Date();
      return now - listDate
    },
    closeView(){
      this.showView=false;
    },
    async getIndexColumn(){
      let res = await this.$api.getIndexColumn();
      console.log('获取全部的内容为',res.data.data);
      this.allData = res.data.data;
      $(".app-loading").hide()
    },
    toCatePage(item,index){
      this.$router.push({
        path:'/management',
        query:{
          id:item.id,
          pid:item.parentId,
          index:index,
          name:this.tab1[this.tabIndex1]
        }
      })
      this.$store.commit('setTabIndex',4)
    },

    //获取轮播数据
    async getBanner(){
      let res = await this.$api.getTopBanner({type:"1,2,3,4,5,6"});
      res.data.data.forEach(item=>{
        if(item.locationType == 1){
          this.banner.push(item)
        }else if(item.locationType == 2){
          this.adv2.push(item)
        }else if(item.locationType ==3){
          this.adv3.push(item)
        }else if(item.locationType == 4){
          this.adv4.push(item)
        }else if(item.locationType == 5){
          this.adv5.push(item)
        }else if(item.locationType == 6){
          this.adv6.push(item)
        }
      })
      console.log(this.banner,'banner')
    },
    async getSwiper(){
      let res = await this.$api.getIndexCharList()
      setTimeout(()=>{
        loadSwiper();
      },100)
      this.swiper = res.data.data;
    },
    async getCards(){
      let res = await this.$api.getIndexImgList()
      this.cards = res.data.data
      setTimeout(()=>{
        loadSwiper();
      },100)
    },
    // async getBulletin(){
    //   let res = await this.$api.getBulletin();
    //   // this.tab1 = res.data.data;
    //   // this.getTab1();
    // },
    async getTab1(){
      let res = await this.$api.getColumn({
        columnName:this.tab1[this.tabIndex1]
      })
      if(res.data.data){
        this.tabData1 = res.data.data
      }else{
        this.tabData1 = []
      }

      console.log(this.tabData1)
    },
    //获取认证业务数据
    async getCert(name){
      let res = await this.$api.getChildByColumn({columnName:name});
      console.log(res.data,'-------------------')
      this.certData = res.data.data
    },
    tabClick1(index){
      this.tabIndex1 = index;
    },
    async gethydt(){
      let res = await this.$api.getColumn({columnName:'行业动态'});
      this.hydt = res.data.data;
    },
    async getgzdt(){
      let res = await this.$api.getColumn({columnName:'工作动态'});
      this.gzdt = res.data.data;
      console.log(this.gzdt)
    },
    async getTab2(){
      let res = await this.$api.getColumn({
        columnName:this.tab2[this.tabIndex2]
      })
      if(res.data.data){
        this.tabData2 = res.data.data
      }else{
        this.tabData2 = []
      }
      console.log(this.tabData2)
    },
    tabClick2(index){
      this.tabIndex2 = index;
      // this.getTab2()
    },
    async getPartner(){
      let res = await this.$api.getPartner();
      // console.log(res.data)
      this.partner = res.data.data
    },
    async getTab3(){
      let res = await this.$api.getColumn({
        columnName:this.tab3[this.tabIndex3]
      })
      if(res.data.data){
        this.tabData3 = res.data.data[0]
      }else{
        this.tabData3 = []
      }
      console.log(this.tabData3,'tabData3')
    },
    tabClick3(index){
      this.tabIndex3 = index;
      // this.getTab3()
    },
    async getzspj(){
      let res = await this.$api.getColumn({columnName:'知识普及'});

      if(res.data.data){
        this.zspj = res.data.data[0];
      }else{
        this.zspj = []
      }
      console.log(this.zspj)
    },
    async getFriendLink(){
      let res = await  this.$api.getFriendLink();
      console.log(res.data)
      this.fLinks = res.data.data
    },
    toMore(item){
      this.$router.push(`/channels/${item.topId}/${item.id}.html`)
    },
    toMore2(item,id){
      this.$router.push(`/channels/${item.topId}/${id}.html`)
    },
    toDetail(item,id){
      if(this.$store.state.stations.id == '1004' && item.name == '证书公告'){
        return this.$router.push(`/channels/${item.topId}/${item.id}.html`)
      }
      this.$router.push(`/contents/${item.topId}/${item.id}/${id}.html`)
    },
    toDetail2(item){
      this.$router.push(`/contents/${item.topId}/${item.columnId}/${item.id}.html`)
    },
    setNavIndex(pid){

    },
    navTo(item){
      if(item.skipUrl.includes('http')){
        window.open(item.skipUrl);
      }else{
        this.$router.push(item.skipUrl)
      }
    }
  }
}
</script>

<style scoped >
.box1{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box1 .box-item{
  width: 48%;
  height: 55px;
  border: 1px solid #d9e6ff;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
}
.box-item:before{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 8px solid #d9e6ff;
  height: 70%;
  margin: auto 0;
}
.box-item:nth-child(1){
  margin-top: 15px;
}
.box-item:nth-child(2){
  margin-top: 15px;

}
.btn{
  font-size: 14px;
  width: 80px;
  height: 26px;
  border-radius: 34px;
  display: block;
  background: #244991;
  color: #fff;
}
</style>
